import React from 'react'

const APIButton = (props) => {

    const { title, onBtnClick, loading, className, disabled } = props

    return (
        <button 
            className={`loader-btn ${className}`} disabled={disabled || loading} 
            onClick={(e) => onBtnClick && !loading ? onBtnClick(e): {}} 
        >
            {title}
            {loading ? <span className="loading"></span> : null}
        </button>
    )
}

export default APIButton
 