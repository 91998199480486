import React, { useEffect, useState, useRef } from "react"
import toast from "react-hot-toast"
import { graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import SimpleReactValidator from "simple-react-validator"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import APIButton from "../components/addon/button/apiButton"

import DOCTOR_API from '../api/doctors'
import PATIENT_API from "../api/refer-patient"
import useScript from "../service/useScript"
import { acceptOnlyNumbers, getCookie } from "../service/helper"


const OnlineBills = (props) => {
    const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatags ? props?.data.allNodeTemplatePages.edges[0].node.metatags : []
    const schema = props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema ? props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = props?.data.allNodeTemplatePages.edges[0].node?.metatags_on_page || {}
    // useScript("/assets/js/form-inputs-anim.js")
    // useScript("/assets/js/online-interim-bills.js")

    const [admissionNo, setAdmissionNo] = useState('')
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(null)
    const [data, setData] = useState(null)

    const [, forceUpdateForRegister] = useState();
    useScript("/assets/js/login-register.js");
    const [validator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))

    const handleSubmit = (e) => {
        e.preventDefault();
        const result = validator.current.allValid()
        if(!result) {
            validator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(result && admissionNo?.length) {
            getDataFromAdmissionNo()
        }
    }

    const getDataFromAdmissionNo = () => {
        setLoading(true)
        PATIENT_API.getPatientByAdmissionNo(admissionNo)
        .then(res => {
            if(res?.Data) {
                setData(res?.Data)
                setStep(2)
            } else {
                toast.error(res?.message ? res?.message : 'Something went wrong')
            }
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
            toast.error(err?.response?.data ? err?.response?.data : 'Something went wrong')
        })
    }

    const sendBillUsingEmail = () => {
        setLoading(true)
        let payload = {
            admissionNumber: data?.adm_no
        }
        DOCTOR_API.emailInterimBill(payload)
        .then(res => {
            if(res?.success) {
                setStep(3)
            } else {
                toast.error(res?.message ? res?.message : 'Something went wrong')
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            toast.error(err?.message ? err.message : 'Something went wrong' )
        })
    }

    const goToStep1 = () => {
        setStep(null)
        setAdmissionNo('')
    }

    useEffect(() => {
        if(getCookie('hh')) {
            navigate(`/user/my-bills`)
        }
    },[getCookie])

    return (
    <>
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/login.css", "/assets/css/accessibility.css"],
                }}
                tags={{metaTags,canonicalUrl}}
            />
             <Schema schema={schema} />
            <main className="innerpage">
                {!step && 
                <section className="section_bg enter_details_section pt-first-section">
                    <div className="container">
                        <h2 className="section-heading">Online Interim Bill</h2>
                        <div className="row no-gutters">
                            <div className="col-lg-12 col-md-12">
                                <div className="white_rounded_box">
                                    <div className="form_wrap">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group -animated">
                                                    <label>
                                                        Please enter your Admission No.
                                                        <span className="mandatory">*</span>
                                                    </label>
                                                    <input className="form-control" type="text" value={admissionNo} onChange={(e) => setAdmissionNo(acceptOnlyNumbers(e?.target?.value))} name="admissionNo" />
                                                    {validator.current.message('admissionNo', admissionNo, 'required')}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn_wrap">
                                            <APIButton title={`Submit`} 
                                                loading={loading} 
                                                disabled={loading} 
                                                onBtnClick={(e) => handleSubmit(e)}  
                                                className={`btn btn-primary continue_btn`} 
                                            />
                                            {/* <button className="btn btn-primary continue_btn">Submit</button> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-12 pt-lg-5 pt-md-3">
                                                <p>
                                                    This is an interim provisional bill and a few
                                                    services may or may not be included in this
                                                    statement.
                                                </p>
                                                <p>
                                                    For any further information/clarification, please
                                                    contact Billing Officer on duty.
                                                </p>
                                            </div>
                                        </div>
                                        {/* <!-- <div className="not_a_member">
                                            Not a Member? <a href="#">Click here to Register</a>
                                            </div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                {step === 2 && 
                <section className="confirmation_section section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="tankyou_div">
                                <h2 className="section-heading">Confirmation</h2>
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g data-name="Group 2657" transform="translate(0)">
                                            <path data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"></path>
                                            <g data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                                <line data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Kindly confirm your Admission No. {data?.adm_no || ''} and Email ID - {data?.emailAddress || ''}</p>
                                    <p className="mt-4"> <span>If correct</span> - Please proceed</p>
                                    <p> <span>If incorrect</span> – Please go back and enter your correct Admission no.</p>
                                </div>
                                <div className="btn_wrap btn_group">
                                    <button className="btn btn-primary btn-primary-solid back_btn" onClick={goToStep1}>Back</button>
                                    <APIButton title={`Proceed`} 
                                        loading={loading} 
                                        disabled={loading} 
                                        onBtnClick={(e) => sendBillUsingEmail()}  
                                        className={`btn btn-primary continue_btn`} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                {step === 3 && 
                <section className="confirmation_section2 section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="tankyou_div">
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g data-name="Group 2657" transform="translate(0)">
                                            <path data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"></path>
                                            <g data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                                <line data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Your interim bill has been sent to your registered email id.</p>
                                </div>
                                <div className="btn_wrap btn_group">
                                    <button className="btn btn-primary btn-primary-solid back_btn" onClick={() => setStep(2)}>Back</button>
                                    <button className="btn btn-primary continue_btn" onClick={() => navigate('/')}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </main>
        </Layout>
    </>
    )   
}

export const query = graphql`
query onlineInterimBillQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/online-interim-bills$/"}}}) {
        edges {
          node {
            id
            title
            path {
              alias
            }
            relationships{
                field_seo_schema{
                    field_seo_title
                    field_seo_schema_text_area
                }
            }
            metatags: metatag_normalized {
              attributes {
                content
                name
                property
              }
            }
            metatags_on_page: field_meta_tags{
                canonical_url
              }
          }
        }
    }
  }
`

export default OnlineBills