import axios from "axios"

import config from "../service/config"

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
})

let AuthAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    auth: {
        username: config.GATSBY_DRUPAL_USERNAME,
        password: config.GATSBY_DRUPAL_PASSWORD
    }
})

myAxios.interceptors.request.use(function (config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});

const api = {

    submitPatientDetails(patientData)
    {
        return AuthAxios.post(config.REFER_PATIENT,patientData)
    },
    
    getPatientByAdmissionNo(no) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_PATIENT_BY_ADMISSION_NO+'/'+no)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },

    getPatientByReservationNo(no) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.GET_PATIENT_BY_RESERVATION_NO+'/'+no)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })        
    },

}

export default api